export const getPositions = () => {
    const card = $(".logged_in").find("[data-action=getPositions]"),
    items = card.find('.list-group-item').not(".link"),
    object = card.data('object');
    
    var request = $.ajax({
        type: "GET",
        dataType: "json",
        url: '/utils/api/dashboard/cards.php?action=getPositions'
    });

    $.when( request ).done( function( response ) {
        if(response.total == 0) {
            card.find('.list-group').remove();
            card.find('.card-body').append('<p>No ' + object + 's at this time.</p>');
            return;
        }
        
        if(response.total < items.length) {
            const diff = items.length - response.total;
            for (let i = items.length; i >= diff; i--) {
                items[i-1].remove();
            }
        };
        
        if(response.total < items.length) {
            for (i = 0; i < response.total; i++) {
                items.pop();
            }
        };
        
        items.each(function(index) {
            const title = response.models[index].judge._label,
            clerkshipType = response.models[index].clerkship_type._label,
            badge = response.models[index].system_flag_label,
            cityState = response.models[index].city_state,
            court = response.models[index].sao_court ? response.models[index].sao_court : response.models[index].court;

            $(this).find('.title').removeClass('line title').html(title);
            $(this).find('.loading-badge').removeClass('line loading-badge').html(badge);
            $(this).find('.list-item-subtitle').empty().html(clerkshipType);
            $(this).find('.list-data-columns.first').empty().html(cityState);
            $(this).find('.list-data-columns.second').empty().html(court.replace(/(?:&nbsp;|<br>)/g,''));
            $(this).find('.date-action').empty().html(response.models[index].date_tag);
        });
    });

}