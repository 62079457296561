export const getApplications = () => {
    const card = $(".logged_in").find("[data-action=getApplications]"),
    id = card.data('id'),
    object = card.data('object'),
    items = card.find('.list-group-item').not(".link");
    
    var request = $.ajax({
        type: "POST",
        dataType: "json",
        url: '/utils/api/dashboard/cards.php?action=getApplications',
        data: {
            id: id
        },
    });


    $.when( request ).done( function( response ) {
        if(response.total == 0) {
            card.find('.list-group').remove();
            card.find('.card-body').append('<p>No ' + object + 's at this time.</p>');
            return;
        }
        
        if(response.total < items.length) {
            for (let i = items.length; i > response.total; i--) {
                items[i-1].remove();
            }
        };

        items.each(function(index) {
            if(index == response.total) {
                return false;
            }

            const title = response.models[index].judge._label,
            clerkshipType = response.models[index].judge_type,
            badge = response.models[index].system_flag_label,
            cityState = response.models[index].city_state,
            court = response.models[index].court;

            $(this).find('.title').removeClass('line title').html(title);
            $(this).find('.loading-badge').removeClass('line loading-badge').html(badge);
            $(this).find('.list-item-subtitle').empty().html(clerkshipType);
            $(this).find('.list-data-columns.first').empty().html(cityState);
            $(this).find('.list-data-columns.second').empty().html(court.replace(/(?:&nbsp;|<br>)/g,''));
            $(this).find('.date-action').empty().html(response.models[index].date_tag);
        });
    });

}

export const getApplicationsLSA = () => {
    const card = $(".logged_in").find("[data-action=getApplicationsLSA]"),
    id = card.data('id'),
    object = card.data('object'),
    items = card.find('.list-group-item').not(".link");
    
    var request = $.ajax({
        type: "POST",
        dataType: "json",
        url: '/utils/api/dashboard/cards.php?action=getApplicationsLSA',
        data: {
            id: id
        },
    });


    $.when( request ).done( function( response ) {
        if(response.total == 0) {
            card.find('.list-group').remove();
            card.find('.card-body').append('<p>No ' + object + 's at this time.</p>');
            return;
        }
        
        if(response.total < items.length) {
            for (let i = items.length; i > response.total; i--) {
                items[i-1].remove();
            }
        };

        items.each(function(index) {
            if(index == response.total) {
                return false;
            }

            const title = response.models[index].applicant_fullname,
            email = response.models[index].email,
            recommendations = response.models[index].recommendations,
            closedate = response.models[index].job_close_date;

            $(this).find('.title').removeClass('line title').html(title);
            $(this).find('.loading-badge').removeClass('line loading-badge').remove();
            $(this).find('.list-item-subtitle').empty().html(email);
            $(this).find('.list-data-columns.first').empty().html('<font color="red">' + recommendations + '</font> Pending Recommendations For Finalized Applications');
            $(this).find('.list-data-columns.second').empty().html('Closest Position Deadline: <font color="red">'+ closedate +'</font>');
            $(this).find('.date-action').remove();
        });
    });

}