import { getPositions } from './dashboard-modules/positions.js';
import { getApplications, getApplicationsLSA } from './dashboard-modules/applications.js';
import $ from 'jquery';

function callFunction (name) {
    switch (name) {
        case "getPositions": getPositions(); break;
        case "getApplications": getApplications(); break;
        case "getApplicationsLSA": getApplicationsLSA(); break;
    }
}
 
$(function() {
    const ajaxCards = $('.ajax-card');
    ajaxCards.each( function(index) {
        const fn = $(this).data('action');
        callFunction(fn);
    });
});